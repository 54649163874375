import { categoriesIcon } from './categories';
import { chartDoubleYIcon } from './chart-double-y';
import { chartSingleYIcon } from './chart-single-y';
import { chevronDownIcon } from './chevron-down';
import { clientsIcon } from './clients';
import { createIcon } from './create';
import { doubleChevronIcon } from './double-chevron';
import { pencilIcon } from './pencil';
import { policyIcon } from './policy';
import { projectsIcon } from './projects';
import { questionMarkIcon } from './question-mark';
import { sadFaceIcon } from './sad-face';
import { settingsIcon } from './settings';
import { tableChartIcon } from './table-chart';
import { tacticCoIcon } from './tactic-co';
import { tacticLpoIcon } from './tactic-lpo';
import { tacticSgIcon } from './tactic-sg';
import { tacticIcon } from './tactic';
import { universeIcon } from './universe';
export const integratedSearchIcons = [categoriesIcon, chartDoubleYIcon, chartSingleYIcon, chevronDownIcon, clientsIcon, createIcon, doubleChevronIcon, pencilIcon, policyIcon, projectsIcon, questionMarkIcon, sadFaceIcon, settingsIcon, tableChartIcon, tacticCoIcon, tacticLpoIcon, tacticSgIcon, tacticIcon, universeIcon];
