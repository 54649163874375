import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { SvgIconRegistry } from '@ngneat/svg-icon';
import { UntilDestroy } from '@ngneat/until-destroy';

import { SHARED_ICON_SIZES } from '@ess/shared/utils/consts';
import { SharedIconSize, SharedIconType } from '@ess/shared/utils/models';

@UntilDestroy()
@Directive({
  selector: '[essIcon]',
  standalone: true,
})
export class SharedUiIconDirective {
  @Input() set essIcon(name: string | SharedIconType | null | undefined) {
    this.__renderEmpty();
    if (name) {
      this.__renderContainer();

      const svg: SVGSVGElement | undefined = this.__svgRegistry.getSvgElement(name);
      svg ? this.__renderSharedIcon(svg) : this.__renderMatIcon(name);
    }
  }

  @Input() set essIconSize(size: SharedIconSize) {
    this.__iconSize = size;
    this.__setElementSize();
  }

  protected get _size(): string {
    return SHARED_ICON_SIZES[this.__iconSize];
  }

  private __iconSize: SharedIconSize = 'md';

  constructor(
    private readonly __element: ElementRef,
    private readonly __renderer: Renderer2,
    private readonly __svgRegistry: SvgIconRegistry,
  ) {}

  private __renderSharedIcon(svg: SVGSVGElement): void {
    this.__renderer.appendChild(this.__element.nativeElement, svg);
  }

  private __renderMatIcon(name: string): void {
    const matIcon = this.__element.nativeElement;
    this.__renderer.addClass(matIcon, 'material-symbols-outlined');
    const iconName = name.split('_filled')[0];
    iconName !== name && this.__renderer.addClass(matIcon, 'shared-icon-filled');
    this.__renderer.appendChild(matIcon, this.__renderer.createText(iconName));
  }

  private __renderEmpty(): void {
    this.__renderer.removeAttribute(this.__element.nativeElement, 'style');
    this.__element.nativeElement.innerHTML = '';
  }

  private __renderContainer(): void {
    this.__renderer.addClass(this.__element.nativeElement, 'shared-icon');
    this.__setElementSize();
  }

  private __setElementSize(element: HTMLElement = this.__element.nativeElement, size: string = this._size): void {
    this.__renderer.setStyle(element, 'width', size);
    this.__renderer.setStyle(element, 'height', size);
    this.__renderer.setStyle(element, 'fontSize', size);
    this.__renderer.setStyle(element, 'flexShrink', 0);
  }
}
