import { ModuleWithProviders, NgModule } from '@angular/core';
import { provideSvgIconsConfig } from '@ngneat/svg-icon';
// eslint-disable-next-line import/no-unresolved
import { SVG_CONFIG } from '@ngneat/svg-icon/lib/providers';

@NgModule()
export class SharedUiIconsModule {
  static forRoot(svgConfig: Partial<SVG_CONFIG>): ModuleWithProviders<SharedUiIconsModule> {
    return {
      ngModule: SharedUiIconsModule,
      providers: [provideSvgIconsConfig(svgConfig)],
    };
  }
}
